var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layer-page-div"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowBigImg),expression:"isShowBigImg"}],staticClass:"big-img-div",on:{"click":_vm.onHideBigImgDiv}},[_c('img',{ref:"bigImg",attrs:{"src":"","alt":""}})]),_c('div',{staticClass:"layer-content-div"},[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("品牌：")]),_c('div',{staticClass:"item-content-div"},[_c('my-select',{staticClass:"lecturer-select",attrs:{"load-more":true,"action":_vm.teachBrandNameAction,"change":_vm.onBrandChanged,"placeholder-txt":"请选择品牌"}})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("故障代码：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.faultInfo.code),expression:"faultInfo.code"}],staticClass:"video-name-div",attrs:{"name":"faultCode","type":"text","maxlength":"60","placeholder":"请输入故障代码"},domProps:{"value":(_vm.faultInfo.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faultInfo, "code", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("故障点：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.faultInfo.point),expression:"faultInfo.point"}],attrs:{"name":"introduce","maxlength":"200","placeholder":"请输入故障点"},domProps:{"value":(_vm.faultInfo.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faultInfo, "point", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("故障处理：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.faultInfo.msg),expression:"faultInfo.msg"}],attrs:{"name":"introduce","maxlength":"200","placeholder":"请输入故障处理"},domProps:{"value":(_vm.faultInfo.msg)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faultInfo, "msg", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("图片：")]),_c('div',{staticClass:"item-content-div"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.faultImg != null),expression:"faultImg != null"}],staticClass:"video-img-div"},[_c('img',{staticClass:"del-img",attrs:{"src":require("../../assets/del.png"),"alt":""},on:{"click":_vm.onDelImg}}),_c('img',{ref:"img",attrs:{"src":"","alt":""},on:{"click":function($event){return _vm.onShowBigImgDiv($event)}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.faultImg == null),expression:"faultImg == null"}]},[_c('label',{staticClass:"upload-img-label",on:{"change":function($event){return _vm.onAddImg($event)}}},[_c('img',{staticClass:"cursor-el",attrs:{"src":require("../../assets/picture.png"),"alt":""}}),_c('input',{staticClass:"cursor-el",attrs:{"type":"file"}})])])])]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("视频文件：")]),_c('div',[_c('input',{staticClass:"cursor-el",attrs:{"type":"file"},on:{"change":function($event){return _vm.onAddFile($event)}}})])]),(_vm.mediaFile != null)?_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("播放时长：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.faultInfo.time),expression:"faultInfo.time"}],attrs:{"name":"videoTime","type":"text","placeholder":"请输入播放时长"},domProps:{"value":(_vm.faultInfo.time)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faultInfo, "time", $event.target.value)}}}),_vm._v("分钟")]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4019565902)})],1)]):_vm._e(),(_vm.mediaFile != null)?_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("价格：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.faultInfo.price),expression:"faultInfo.price"}],attrs:{"name":"videoPrice","type":"text","maxlength":"8","placeholder":"请输入价格"},domProps:{"value":(_vm.faultInfo.price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faultInfo, "price", $event.target.value)}}}),_vm._v("元")]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3083586715)})],1)]):_vm._e(),_c('div',{staticClass:"operate-div"},[_c('div',{staticClass:"tips-div text-c err-tips"},[_vm._v(_vm._s(_vm.tipMsg))]),_c('div',{staticClass:"text-c"},[_c('input',{attrs:{"type":"button","value":"取消"},on:{"click":_vm.onCancel}}),_c('input',{staticClass:"save-input",attrs:{"type":"button","value":"保存"},on:{"click":_vm.onSave}})])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }