<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">品牌：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="teachBrandNameAction" :change="onBrandChanged" placeholder-txt="请选择品牌"/>
            <label>{{`(${brandName})`}}</label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">故障代码：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-div" name="faultCode" type="text" v-model="faultInfo.code" maxlength="60" placeholder="请输入故障代码"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">故障点：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="faultInfo.point" maxlength="200" placeholder="请输入故障点"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">故障处理：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="faultInfo.msg" maxlength="200" placeholder="请输入故障处理"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div" v-if="isPrice">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input name="videoPrice" type="text" v-model="faultInfo.price" maxlength="8" placeholder="请输入价格">元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getTeachBrandNameItems, getFaultCodeInfo, postFaultCodeEdit } from "@/common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    faultCodeId: {
      type: String,
      default: null
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      faultInfo: {
        brandId: "",
        code: "",
        point: "",
        msg: "",
        price: ""
      },
      teachBrandNameAction : getTeachBrandNameItems,
      brandName: null,
      isPrice: false,
      tipMsg: ""
    }
  },
  created() {
    if(this.faultCodeId == null){
      this.$layer.close(this.layerid);
      this.$layer.msg("请选择故障代码");
      return;
    }

    getFaultCodeInfo({id: this.faultCodeId})
      .then(data => {
        this.isPrice = Object.prototype.hasOwnProperty.call(data, "price");
        if(this.isPrice){
          this.faultInfo.price = data.price / 100;
        }
        this.faultInfo.code = data.code;
        this.faultInfo.point = data.point;
        this.faultInfo.msg = data.msg;
        this.brandName = data.brandName;
      })
      .catch(error => {
        this.$layer.close(this.layerid);
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.$layer.msg(error.data.msg);
        } else {
          this.$layer.msg("查询故障代码信息失败，请稍后重试");
        }
      });
  },
  methods: {
    onBrandChanged(item) {
      this.faultInfo.brandId = item.id
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.faultInfo.brandId <= 0){
        this.setTipsMsg("请选择品牌");
        return;
      }
      if(this.isPrice && this.faultInfo.price < 0){
        this.setTipsMsg("请输入视频价格");
        return;
      }

      let formData = new FormData();
      formData.append("id", this.faultCodeId);
      formData.append("brandId", this.faultInfo.brandId);
      formData.append("code", this.faultInfo.code);
      formData.append("point", this.faultInfo.point);
      formData.append("msg", this.faultInfo.msg);
      if(this.isPrice){
        formData.append("price", `${this.faultInfo.price * 100}`);
      }
      this.$refs.form.validate().then(res => {
        if(res){
          postFaultCodeEdit(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
        margin-right: 15px;
      }
      .item-content-div {
        flex: 1;
        display: flex;
        align-items: center;
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .lecturer-select {
          width: 230px;
          height: 33px;
          margin-right: 5px;
        }
        .video-name-div {
          width: 218px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>