<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">品牌：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="teachBrandNameAction" :change="onBrandChanged" placeholder-txt="请选择品牌"/>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">故障代码：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-div" name="faultCode" type="text" v-model="faultInfo.code" maxlength="60" placeholder="请输入故障代码"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">故障点：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="faultInfo.point" maxlength="200" placeholder="请输入故障点"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">故障处理：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="faultInfo.msg" maxlength="200" placeholder="请输入故障处理"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">图片：</div>
          <div class="item-content-div">
            <div class="video-img-div" v-show="faultImg != null">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg">
              <img ref="img" src="" alt="" @click="onShowBigImgDiv($event)">
            </div>
            <div v-show="faultImg == null">
              <label class="upload-img-label" @change="onAddImg($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频文件：</div>
          <div>
            <input class="cursor-el" type="file" @change="onAddFile($event)"/>
          </div>
        </div>
        <div class="item-div" v-if="mediaFile != null">
          <div class="item-name-div">播放时长：</div>
          <div class="item-content-div">
            <validation-provider rules="required|numeric" v-slot="{ errors }">
              <label><input name="videoTime" type="text" v-model="faultInfo.time" placeholder="请输入播放时长">分钟</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div" v-if="mediaFile != null">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input name="videoPrice" type="text" v-model="faultInfo.price" maxlength="8" placeholder="请输入价格">元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getTeachBrandNameItems, getCancelTokenSource, postFaultCodeAdd, postFaultCodeAdd2 } from "@/common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      faultInfo: {
        brandId: "",
        code: "",
        point: "",
        msg: "",
        time: "",
        price: ""
      },
      uploadMsg: {},
      teachBrandNameAction : getTeachBrandNameItems,
      faultImg: null,
      mediaFile: null,
      isShowBigImg: false,
      tipMsg: ""
    }
  },
  methods: {
    onBrandChanged(item) {
      this.faultInfo.brandId = item.id
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.faultImg = img;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onAddFile(event) {
      if(event.target.files.length < 1){
        return;
      }
      this.mediaFile = event.target.files[0];
    },
    onDelImg() {
      this.faultImg = null;
      this.$refs.img.src = "";
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.faultInfo.brandId <= 0){
        this.setTipsMsg("请选择品牌");
        return;
      }
      if(this.faultImg == null){
        this.setTipsMsg("请选择图片");
        return;
      }
      if(this.mediaFile != null){
        if(this.faultInfo.time < 1){
          this.setTipsMsg("请输入视频播放时长");
          return;
        }

        if(this.faultInfo.price < 0){
          this.setTipsMsg("请输入视频价格");
          return;
        }
      }

      let formData = new FormData();
      formData.append("brandId", this.faultInfo.brandId);
      formData.append("code", this.faultInfo.code);
      formData.append("point", this.faultInfo.point);
      formData.append("msg", this.faultInfo.msg);
      formData.append("pic", this.faultImg);
      if(this.mediaFile == null){
        this.$refs.form.validate().then(res => {
          if(res){
            postFaultCodeAdd2(formData)
              .then(() => {
                this.refreshData();
                this.$layer.close(this.layerid);
                this.$layer.msg("操作成功");
              })
              .catch(error => {
                if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                  this.$layer.msg(error.data.msg);
                } else {
                  this.$layer.msg("操作失败");
                }
              });
          }
        });
      }else{
        formData.append("time", this.faultInfo.time);
        formData.append("price", `${this.faultInfo.price * 100}`);
        formData.append("file", this.mediaFile);

        this.$refs.form.validate().then(res => {
          if(res){
            this.uploadMsg = {
              source: getCancelTokenSource(),
              name: this.mediaFile.name,
              total: 0,
              loaded: 0,
              state: 3,
            }
            this.$store.state.uploadQueue.push(this.uploadMsg);
            postFaultCodeAdd(formData, this.uploadMsg.source.token, this.onUploadProgress)
                .then(() => {
                  this.uploadMsg.state = 1;
                })
                .catch(error => {
                  if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                    this.uploadMsg.state = error.data.status === -1 ? -1 : 0;
                  } else {
                    this.uploadMsg.state = 0;
                  }
                });

            this.$layer.close(this.layerid);
          }
        });
      }
    },
    onUploadProgress(progressEvent) {
      this.uploadMsg.total = progressEvent.total;
      this.uploadMsg.loaded = progressEvent.loaded;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
        margin-right: 15px;
      }
      .item-content-div {
        display: inline-flex;
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .lecturer-select {
          width: 230px;
          height: 33px;
        }
        .video-name-div {
          width: 218px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
      .video-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
        }
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>